import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";


// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  //width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;



const ConnectButton = styled(WalletDialogButton)`

background-color: #0693e3 !important
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div`
  display: flex;
  padding:20px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  max-width:200px;
  height:300px;
  
  padding: 24;
  border-radius: 30px;
 // border: "0px dashed #1a1a1a";
 //width: 100%;
 // background-image:"none";
 // background-size: cover;
 // background-position: center
`; 

const MintButton = styled(Button)`
  width: 90px;
  height: 50px;
  //display: flex;
  //flex: 2;
  //flex-direction: column;
  justify-content: center !important;
  border-radius: 20px !important;
  align-items: center;
  background-color: #ff1d56f1 !important;
  
  font-weight: bold !important;
  font-size:20px !important;
  
 

`; // add your styles here

export const StyledLogo = styled.img`
padding:30px;
  width: 200px;
  @media (min-width: 767px) {
    width: 300px; 
  }
 
  transition: width 0.5s;
  transition: height 0.5s;
`; 

export const StyledSocial = styled.img`
padding:8px;
  width: 60px;
  @media (min-width: 767px) {
    width: 60px; 
  }
 
  transition: width 0.5s;
  transition: height 0.5s;
`; 

export const ResponsiveWrapper = styled.div`
  display: flex;
  
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: center;
  //width: 100%; 
  //background-color: green;
 

  @media (min-width: 1000px) {
    flex-direction: row; 
    
  }
`;

export const TextTitle = styled.p`
  color: #1a1a1a;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
 
`;

export const ContainerImage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 
  width: 100%;
  background-image: "none";
  background-size: cover;
  background-position: center;
  order:2;
  
  @media (min-width: 1000px) {
    order:0;
    
  }
`;

export const StyledImg = styled.img`
  border-radius: 10%; 
  width: 200px; 
  height: 200px;
  
  @media (min-width: 900px) {
    width: 250px; 
    height: 250px;
     
  }
  @media (min-width: 1000px) {
    width: 300px;
    height: 300px;
    
  }

  
 // transition: width 0.5s; 
`;

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main
      style={{    
        //backgroundColor: "pink",
        backgroundImage: "url(/images/bg.jpg)",
        backgroundSize: "cover", 
        height: "100vh",
      }}
    >

      <div 
        style= {{padding: 0, display: "flex", flex: 1, flexDirection: "column"}}>
          <div
            style={{
              backgroundColor: "black",
              display: "flex",
               justifyContent: "space-between",
               }}
           >
               {wallet && (
               <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
               )}
              <div>
              
               <ConnectButton>
               {wallet ? "Connected" : "Connect Wallet"}
               </ConnectButton>
               </div>
         </div>
         
          <div style= {{
            padding: 0, 
            display: "flex", 
            flex: 1, 
            flexDirection: "row",
            justifyContent: "center"}}
            
          >
              
              <StyledLogo alt={"logo"} src={"/images/logo.png"} />
             
          </div>
          
      <div> 
      <ResponsiveWrapper style={{
      
     
          //height: "100vh"
      }}
      >
       <ContainerImage>
          <StyledImg alt={"vlade"}  src={"/images/vlade.png"} /> 
        </ContainerImage>

     

        

      <MintContainer >
        

        <MintButton
          disabled={isSoldOut || isMinting || !isActive}
          onClick={onMint}
          variant="contained"
        >
          {isSoldOut ? (
            "SOLD OUT"
          ) : isActive ? (
            isMinting ? (
              <CircularProgress />
            ) : (
              "MINT"
            )
          ) : (
            <Countdown
              date={startDate}
              onMount={({ completed }) => completed && setIsActive(true)}
              onComplete={() => setIsActive(true)}
              renderer={renderCounter}
            />
          )}
        </MintButton>
        <SpacerSmall />
      1 80SM cost 0.5 SOL
      <div>
        

        
        {wallet && (
          <TextTitle
            style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                color: "white",
              }}
          >
            {itemsRedeemed}/{itemsAvailable}
          </TextTitle>
          )}
        
        
      </div>
      </MintContainer>
      
      
        <ContainerImage>
            <StyledImg
              alt={"example"}
              src={"/images/estar.png"}   
            />    
          </ContainerImage>
          </ResponsiveWrapper>
    </div>
    <SpacerMedium/>
    <TextTitle
    style={{
      textAlign: "center",
      fontSize: 20,
      fontWeight: "bold",
      color: "black",
    }}
    
    >
    
     EsCifi an fAntasi moBies 
     exclusibe micro NFT siRies 
     <SpacerSmall/>
     Total Supply: Onli 70
     <SpacerSmall/>
     Siries-2 CoMin Soon
     </TextTitle>
  
     <div style= {{
            padding: 0, 
            display: "flex", 
            flex: 1, 
            flexDirection: "row",
            justifyContent: "center"}}
            
          >
              <a href="https://twitter.com/80sMobies" target="blank">
              <StyledSocial alt={"logo"} src={"/images/social_twitter.png"} />
              </a>
              <a href="https://discord.gg/d7p4F2Re" target="blank">
              <StyledSocial alt={"logo"} src={"/images/social_discord.png"} />
              </a>
             
             
          </div>
          
     
     

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
      </div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
